import { getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { z } from "zod";

const envSchema = z.object({
  NEXT_PUBLIC_AUTH_DISABLED: z.coerce
    .boolean({
      description:
        'Set to "true" to disable authentication for local development',
    })
    .optional(),
  NEXT_PUBLIC_ENABLE_MOCKS: z.coerce
    .boolean({
      description: 'Set to "true" to enable mocks for local development',
    })
    .optional(),
  NEXT_PUBLIC_BYOC_MODE_ENABLED: z.coerce.boolean({
    description: 'Set to "true" to enable BYOC mode',
  }),
  NEXT_PUBLIC_PRICING_FEATURE_ENABLED: z.coerce.boolean({
    description: "Set to 'true' to enable pricing v2",
  }),
  NEXT_PUBLIC_POLLING_INTERVAL: z.coerce.number({
    description:
      "The interval in milliseconds for polling the server for updates",
  }),
});

export const runtimeEnv = envSchema.parse({
  NEXT_PUBLIC_AUTH_DISABLED: process.env.NEXT_PUBLIC_AUTH_DISABLED === "true",
  NEXT_PUBLIC_ENABLE_MOCKS: process.env.NEXT_PUBLIC_ENABLE_MOCKS === "true",
  NEXT_PUBLIC_BYOC_MODE_ENABLED:
    process.env.NEXT_PUBLIC_BYOC_MODE_ENABLED === "true",
  NEXT_PUBLIC_PRICING_FEATURE_ENABLED:
    process.env.NEXT_PUBLIC_PRICING_FEATURE_ENABLED === "true",
  NEXT_PUBLIC_POLLING_INTERVAL: parseFloat(
    process.env.NEXT_PUBLIC_POLLING_INTERVAL ?? "500",
  ),
});

//#regin google auth related
let authDomain = process.env.NEXT_PUBLIC_FRONTEND_URL;
if (!authDomain && process.env.NEXT_PUBLIC_VERCEL_URL) {
  authDomain = process.env.NEXT_PUBLIC_VERCEL_URL;
}

if (authDomain && authDomain.startsWith("http")) {
  const url = new URL(authDomain);
  authDomain = url.host;
}

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: authDomain,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const firebaseApp =
  runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED ||
  runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
    ? undefined
    : getApps().length === 0
      ? initializeApp(firebaseConfig)
      : getApps()[0];

export const googleAuth =
  runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED ||
  runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
    ? undefined
    : getAuth(firebaseApp);

if (
  !runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED &&
  !runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
) {
  firebase.initializeApp(firebaseConfig);
}
//#endregion

type RuntimeFlags = {
  authMode: "self-host-byoc" | "google";
  pricingV2Enabled: boolean;
};

export const runtimeFlags: RuntimeFlags = {
  // when auth disabled, we pretend it's google
  authMode: runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED
    ? "google"
    : googleAuth
      ? "google"
      : "self-host-byoc",
  pricingV2Enabled:
    runtimeEnv.NEXT_PUBLIC_PRICING_FEATURE_ENABLED &&
    !runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED,
};
